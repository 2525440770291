<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="" class="pb-5" /> <br />
      <b-alert v-model="showPullErrorAlert" variant="danger">{{
        pullErrorMessage
      }}</b-alert>

      <!-- ---------------------------------- Connectors State List ----------------------------------------->

     
      <div class="title">
        Connectors State
        <b-button class="btn bg-transparent btn-outline-light" @click="refresh">
          <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
        </b-button>

        <b-button class="btn bg-transparent btn-outline-light" @click="display_div = !display_div">
          <b-icon icon="caret-right" variant="dark" scale="1" v-if="display_div"></b-icon>
           <b-icon icon="caret-down" variant="dark" scale="1" v-if="!display_div"></b-icon>
        </b-button>
        
      </div>

      <br />
      <br />
      <div v-if="display_div" >
      <div class="bar row" >
        <div class="col-1"></div>
        <div class="col-4">
          <p class="tabTitle">Connector Name</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">State</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Actions</p>
        </div>
      </div>
      <div v-if="endpointStates.length === 0" class="row">
        <p>
          <br />It looks like you don't have any source connectors yet. Please
          <a @click="goToConnectorsPage" class="link">click here</a> to create
          your first connector.
        </p>
      </div>
      <div v-for="con in endpointStates" :key="con.endpointId" class="bar1 row">
        <div class="col-1">
          <img :src="getLogoConnector(con.endpointType)" class="icon" />
        </div>
        <div class="col-4">
          <p class="tabText">{{ con.endpointName }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">{{ con.statusId }}</p>
        </div>
        <div class="col-4">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton-wide"
              @click="modalEndpointLogs(con.endpointId)"
              >Logs</b-button
            >
            <b-button
              variant="primary"
              class="botton-wide blue ml-3"
              v-on:click="repullConnector(con.endpointId)"
              >Pull Again</b-button
            >
          </div>
        </div>
      </div>
      </div>

      <!-- --------------------------------- User Group list ------------------------------ -->

      <div class="top">
        <b-alert v-model="showMigrateErrorAlert" variant="danger">{{
          migrateErrorMessage
        }}</b-alert>
        <div class="mt-4">
          <div class="title">
            Users List
            <b-button
              class="btn bg-transparent btn-outline-light"
              @click="refresh"
            >
              <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
            </b-button>
          </div>
            <b-dropdown  class="plus-for-migrate mt-5"  text="Migration Actions">
            <b-dropdown-item @click="startMigration">Start</b-dropdown-item>
            <b-dropdown-item @click="stopMigration"> Stop</b-dropdown-item>
          </b-dropdown>
           <div class="plus-white-trash mt-5" @click="deleteUsers">
            <b-icon
              class="bicon-trash"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
            >
            </b-icon>
            </div>

          <b-input-group class="plus-white mt-5">
            <b-input-group-prepend is-text>
              <b-icon class="altosio-icon" icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              size="lg"
              v-model="search"
              placeholder="Search..."
            ></b-form-input>
          </b-input-group>

          <div class="plus-entries mt-5">
            <div class="plusIn">
              {{users.length}} entries
            </div>
          </div>
        </div>
      </div>
      <br />
      <br /><br />

      <div class="bar row">
        <div class="col-1">
          <input type="checkbox" class="plan1" v-model="selectAll" />
        </div>
        <div class="col-1">
          <p class="tabTitle">
            <label for="vehicle1"> Status </label>
          </p>
        </div>
        <div class="col-1"></div>  <!-- This is for the Source Endpoint logo -->
        <div class="col-2">
          <p class="tabTitle">Display Name</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Source Email</p>
        </div>
      <div class="col-1"></div>   <!-- This is for the Target Endpoint logo -->
        <div class="col-2">
          <p class="tabTitle">Target Email</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Actions</p>
        </div>
      </div>

      <div v-for="user in filteredList" :key="user.id" class="bar1 row">
        <div class="col-1">
          <input
            type="checkbox"
            class="plan pr-3"
            :value="user.id"
            v-model="selected"
          />
        </div>
        <div class="col-1"  @click="modalLogs(user.id)">
          <p class="tabText">
            <label for="vehicle1"> {{ user.statusId }}</label>
          </p>
        </div>
        <div class="col-1">
          <img :src="getLogoConnector(user.sourceEndpointType)" class="icon" />
        </div>
        <div class="col-2" @click="modalLogs(user.id)">
          <p class="tabText">
            {{ user.displayName }}
          </p>
        </div>
        <div class="col-2">
          <p class="tabText">
            {{ user.sourceEmail }}
          </p>
        </div>
        <div class="col-1">
          <img
            v-if="user.targetEndpointType != null"
            :src="getLogoConnector(user.targetEndpointType)"
            class="target-icon"
          />
          <b-icon
            v-else
            class="bicon-questionmark"
            variant="danger"
            icon="question"
            aria-hidden="true"
          />
        </div>
        <div class="col-2">
          <p class="tabText">{{ user.targetEmail }}</p>
        </div>
        <div class="col-2">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton"
              v-on:click="modalLogs(user.id)"
              >Details</b-button
            >
            <b-icon
              class="bicon"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="modalDeleteUser(user.id)"
            >
            </b-icon>
          </div>
        </div>
      </div>

      <b-modal size="xl" id="user-logs" hide-footer>
        <template #modal-title>
          <span class="modalTitle">User Details</span>
        </template>
        <user-group-list-details :userGroupId="userGroupId" @update-user-group-details="updateDetails" />
      </b-modal>
      <b-modal size="xl" title="Connector logs" id="endpoint-logs" hide-footer>
         <template #modal-title>
          <span class="modalTitle"> Connector logs </span>
        </template>
        <endpointLogs :endpointId="endpointId" />
      </b-modal>
      <b-modal size="xl" id="delete-user-group" hide-footer>
        <deleteUserGroup :userGroupId="userGroupId" @delete-user-group="finishUserDeletion"/>
      </b-modal>

      <!-- --------------------------------- Migration Popup ------------------------------ -->

      <b-modal size="xl" id="popup" ref="popup" hide-footer>
        <div>
          <div class="modal-title">Select Migration Target</div>
          <div class="modal-form">
            <p class="modal-subtitle mt-3">Choose Target Connector</p>
            <b-form-select
              class="modal-shape col-9"
              v-model="selectedTarget"
              :options="Object.keys(targetsChoices)"
            ></b-form-select>
          </div>

          <div class="modal-button mb-5">
            <b-button
              variant="primary"
              class="modal-botton"
              v-on:click="updateUsers()"
              >Start Migration</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import api from "../api/index";
import config from "../config/index";
import userGroupListDetails from "../components/popup/userGroupListDetails.vue";
import EndpointLogs from "../components/popup/endpointLogs.vue";
import deleteUserGroup from "../components/popup/deleteUserGroup.vue";

export default {
  name: "UserGroupList",
  components: {
    sidebar,
    upbar,
    userGroupListDetails,
    EndpointLogs,
    deleteUserGroup,
  },
  data() {
    return {
      search: "",
      users: [],
      logged: false,
      selected: [],
      targetEndpoints: [],
      selectAll: false,
      selectedUsers: [],
      targets: [],
      targetsChoices: {},
      usersNeedingTargetConnector: [],
      selectedTarget: null,
      endpointStates: [],
      test: {},
      userGroupId: null,
      endpointId: null,
      project: null,
      pullErrorMessage: "",
      showPullErrorAlert: false,
      showMigrateErrorAlert: false,
      display_div: true,
      migrateErrorMessage: "",
    };
  },
  computed: {
    filteredList() {
      return this.filteredListMethod();
    }
  },
  watch: {
    selected: function (userIds) {
      this.usersNeedingTargetConnector = [];
      this.selectedUsers = [];
      for (const userId of userIds) {
        const user = this.users.find((x) => x.id === userId);
        if (!user.targetEndpointId) {
          this.usersNeedingTargetConnector.push(user.id);
        }
        this.selectedUsers.push(user.id);
      }
    },

    selectAll: function (val) {
      this.toggleAll(val);
    },
  },
  created() {
    //this.getProjects();
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    this.loadUsers();
    this.getTargets(this.$route.params.id);
    this.loadEndpointStatus();
    this.timer = setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    filteredListMethod() {
      return this.users.filter((user) => {
        return JSON.stringify(user)
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    toggleDiv() {
    this.display_div = !this.display_div;
    },    
    goToConnectorsPage() {
      var projectId = this.$route.params.id;
      this.$router.push("/connectors/" + projectId);
    },
    finishUserDeletion(userId) {
      const identifiedUserIndex = this.users.findIndex((x) => x.id === userId);
      this.users.splice(identifiedUserIndex, 1);
    },
    updateDetails(targetEmailAddress) {
      const user = this.users.find((x) => x.id === this.userGroupId);
      user.targetEmail = targetEmailAddress;
    },
    toggleAll(checked) {
      var itemsWithNoTargetEndpoint = [];
      if(this.search==='')
      {
        //There was NO search query here
          this.selected = checked ? this.users.map((x) => x.id) : [];
          itemsWithNoTargetEndpoint = this.users.filter(
            (x) => x.targetEndpointId === null
          );
          this.usersNeedingTargetConnector = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      else
      {
        //There was a Search query here
         var searchedUsers = this.filteredListMethod();
         this.selected = checked ? searchedUsers.map((x) => x.id) : [];
          itemsWithNoTargetEndpoint =  searchedUsers.filter(
            (x) => x.targetEndpointId === null
          );
          this.usersNeedingTargetConnector = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      
    },
    startMigration() {
      if (this.usersNeedingTargetConnector.length > 0) {
        this.$refs["popup"].show();
      } else {
        this.updateUsers();
      }
    },
     stopMigration() {
      const projectId = this.$route.params.id;
        for (var j = 0; j < this.selectedUsers.length; j++) {
            var user = this.users.find( (x) => x.id === this.selectedUsers[j] );
            const status = { statusId: 8 };
            api.changeUserGroupStatus(projectId, this.selectedUsers[j], status);
            user.statusId = "Stopping";
        }
        this.selected = [];
    },
    hideModal() {
      this.$refs["popup"].hide();
    },
    modalLogs(userId) {
      this.userGroupId = userId;
      this.$bvModal.show("user-logs");
    },
    modalEndpointLogs(endpointId) {
      this.endpointId = endpointId;
      this.$bvModal.show("endpoint-logs");
    },
    modalDeleteUser(userId) {
     this.userGroupId = userId;
      this.$bvModal.show("delete-user-group");
    },
    deleteUsers() {
      const projectId = this.$route.params.id;
      api.deleteUsersGroups(projectId, this.selectedUsers);
      this.selectedUsers.forEach(element => {
        this.finishUserDeletion(element);
      });
      this.selected = [];
      
    },
    updateUsers() {
      const projectId = this.$route.params.id;
      var errorCounter = 0;
      //First, assign the target connector to the Users that are missing a target connector *IF ANY*
      if (this.usersNeedingTargetConnector.length > 0) {
        //1- Get the target Endpoint Id
        const identifiedTargetConnector = this.targetEndpoints.find(x=> x.name === this.selectedTarget);
        var jsonSettings = JSON.parse(identifiedTargetConnector.endpointSettings);
        var targetDomain = jsonSettings["Domain"];
        // //2- Run the Update
        const dataToPost = {
          targetEndpointId: identifiedTargetConnector.id,
          objectsIds: this.usersNeedingTargetConnector,
          domain: targetDomain,
        };

        api.assignTargetEndpointForUserGroups(projectId, dataToPost).then(() => {
          //Once the Target connector is assigned, schedule the migration.
          //3- Schedule the migration
          const status = { statusId: 7 };
          //3-A Check among the selected users, which ones are already working or scheduled
          for (var i = 0; i < this.selectedUsers.length; i++) {
            var user = this.users.find(x => x.id === this.selectedUsers[i] );
            if ( user.statusId === "Queued" || user.statusId === "Working" ) 
            {
              errorCounter++;
              this.migrateErrorMessage = "Failed to start migration on " +  errorCounter + " items. Items already scheduled or working.";
              this.showMigrateErrorAlert = true;
              setTimeout(() => {
                this.showMigrateErrorAlert = false;
              }, 2000);
            } 
            else 
            {
              api.changeUserGroupStatus(projectId, this.selectedUsers[i], status);
              user.statusId = "Queued";
              user.targetEndpointType = identifiedTargetConnector.endpointType;
              if(!user.targetEmail.includes("@"))
              {
                user.targetEmail = user.targetEmail+"@"+targetDomain;
              }  
            }
          }
          this.selected = [];
        });
      } else {
        //Selected USERS ALL have a target connector assigned already
        for (var j = 0; j < this.selectedUsers.length; j++) 
        {
            var user = this.users.find(x => x.id === this.selectedUsers[j]);
            if ( user.statusId === "Queued" || user.statusId === "Working")
            {
              errorCounter++;
              this.migrateErrorMessage = "Failed to start migration on " + errorCounter + " items. Items already scheduled or working.";
              this.showMigrateErrorAlert = true;
              setTimeout(() => {
                this.showMigrateErrorAlert = false;
              }, 2000);
            } 
            else 
            {
              const status = { statusId: 7 };
              api.changeUserGroupStatus(projectId, this.selectedUsers[j], status);
              user.statusId = "Queued";
            }
        }
        this.selected = [];
      }

      this.$refs["popup"].hide();
    },
    refresh() {
      this.loadUsers();
      this.loadEndpointStatus();
    },
    loadUsers() {
      api.getUserGroupOverview(this.$route.params.id).then((response) => {
        this.users = response.responseData; 
        for (let item of this.users) {
          item.statusId = config.STATUS[item.statusId];
          item.displayName =  item.displayName.length > 20 ? item.displayName.substring(0, 20) + '...' :   item.displayName;
          item.sourceEmail =  item.sourceEmail.length > 30 ? item.sourceEmail.substring(0, 27) + '...' :   item.sourceEmail;
          item.targetEmail =  item.targetEmail.length > 30 ? item.targetEmail.substring(0, 27) + '...' :   item.targetEmail;
        }
      });
    },
    loadEndpointStatus() {
      api.getEndpointStatus(this.$route.params.id, 4).then((response) => { 
        //4 for the endpoint type 4 which is Team Chats - We can add Slack Chats and Google Chats to this Group later
        this.endpointStates = response.responseData;
        for (let item of this.endpointStates) {
          item.statusId = config.STATUS[item.statusId];
        }
      });
    },

    repullConnector(connectorId) {
      this.pullErrorMessage = "";
      const identifiedConnectorState = this.endpointStates.find(
        (x) => x.endpointId === connectorId
      );
      if (
        identifiedConnectorState.statusId === "Queued" ||
        identifiedConnectorState.statusId === "Working"
      ) {
        //Do not pull
        this.showPullErrorAlert = true;
        this.pullErrorMessage =
          "Cannot repull connector. Connector pull is already queued or working";
        setTimeout(() => {
          this.showPullErrorAlert = false;
        }, 2000);
      } else {
        this.pullErrorMessage = "";
        api.repullConnector(this.project, connectorId);
        identifiedConnectorState.statusId = "Queued";
      }
    },
    getTargets(id) {
      api.getEndpoints(id).then((response) => {
        this.targetEndpoints = response.responseData.filter(x => x.isTarget && x.endpointType===11);
        for (let item of this.targetEndpoints) {
          this.targetsChoices[item.name] = item.id;
        }
      });
    },
    getLogoConnector(id) {
      const EndpointImages = {
        0: "./planner.png",
        1: "./trello.png",
        2: "./asana.png",
        3: "./todoist.png",
        5: "./teams.png",
        4: "./wrike.png",
        8: "./sharepoint.png",
        11: "./teams-chat.png",
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(EndpointImages[id]);
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
}
.bicon-trash {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 8px;
  vertical-align: -10px;
}
.bicon-questionmark {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
  margin-left: 50px;
  margin-top: 20px;
}
.altosio-icon {
  width: 21px;
  height: 36px;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 70px;
  height: 44px;
  margin-right: 25px;
}
.botton-wide {
  width: 110px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 20px;
  margin-top: 20px;
}
.target-icon {
  margin-right: 20px;
  margin-left: 50px;
  margin-top: 20px;
}
.plus {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plus-for-migrate {
  float: right;
  margin-right: 40px;
  font-style: normal;
  line-height: 30px;
  width: 180px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
}
.plus-white {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-white-trash{
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 30px;
  height: 10px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-entries {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a4192;;
  width: 190 px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
}
.link {
  color: #2a4192;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.blue {
  background: #2a4192;
  border-radius: 5px;
}
.title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.3px;
  margin-top: 48px;
  color: #2a4192;
}
.top {
  margin-top: 60px;
}
.pang {
  position: absolute;
  right: 40px;
}
.pangText {
  position: absolute;
  float: left;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}
/* ----------------------- */
.plan {
  width: 17px;
  height: 17px;
  background-color: gray;
  margin-right: 12px;
  margin-left: 5px;
  margin-top: 28px;
}
.plan1 {
  width: 20px;
  height: 20px;
  background-color: gray;
  margin-right: 9px;
  margin-top: 14px;
}
.modal-form {
  margin-left: 28%;
}
.modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.modal-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.modal-shape {
  width: 480px;
  height: 56px;
}
.modal-botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.modal-button {
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
</style>
